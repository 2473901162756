import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { agencyTheme } from 'common/src/theme/agency';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, AgencyWrapper } from '../containers/Agency/agency.style';
import Navbar from '../containers/Agency/Navbar';
import HighlightSection from '../containers/Agency/HighlightSection';
import FaqSection from '../containers/Agency/FaqSection';
import ContactUsSection from '../containers/Agency/ContactUsSection';
import BannerSection from '../containers/Agency/BannerSection';
// import Footer from '../containers/Agency/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import SEO from '../components/seo';

export default () => {
  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO
          title="Mobile Development"
          description="We build hybrid mobile apps ready for Google Play and the app store."
        />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar turnOffLogoToggling={true} page="service" />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <HighlightSection page="services-mobile-app" />
          <FaqSection />
          <ContactUsSection netlifyForm="contact-services-mobile-app" />
          {/*<Footer />*/}
        </AgencyWrapper>
        {/* End of agency wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
